import 'core-js/stable';

import { FormUtils } from './FormUtils';

class JoinFormUtils extends FormUtils {
  constructor(form = '#join-form', validators = {}) {
    // DOM must be ready to init, due to plan check
    const lang = SG.userLang();
    const passwordValidator = new FormUtils.ValidateMatches(
      'password',
      lang === 'es' ? 'Las contraseñas no coinciden' : "Passwords don't match",
    );
    super(form, { confirm_password: passwordValidator });
    this.lang = lang;

    const notBlankNames = ['email', 'password', 'username'];

    // Check if has a plan input to validate
    if (this.hasInput('plan')) {
      notBlankNames.push('plan');
    }

    // Check if has a promo code input to validate (might need to subclass for
    // this one)
    if (this.hasInput('promo_code')) {
      notBlankNames.push('promo_code');
    }

    this.updateValidators(FormUtils.ValidateNotBlank.create(notBlankNames));
    this.updateValidators(validators);

    this.isSpecialOffer = $('.special-offer').length > 0;

    this.onAltSubmit = this.onAltSubmit.bind(this);
  }

  static showInterstitial({
    message = 'Please wait while we redirect you to our payment processor...',
    delay = 0,
  }) {
    return new Promise((resolve) => {
      $('body').messageSpinner({ message });
      if (delay) {
        window.setTimeout(resolve, delay * 1000);
      } else {
        resolve();
      }
    });
  }

  handleResponseRedir(redir) {
    // Add support for interstitial
    if (typeof redir === 'object' && redir.interstitial) {
      JoinFormUtils.showInterstitial(redir.interstitial).then(
        () => super.handleResponseRedir(redir),
      );
      return true;
    }
    return super.handleResponseRedir(redir);
  }

  // Bind to this to use a diff source from the one selected as primary for
  // this session
  onAltSubmit(e) {
    e.preventDefault();
    this.submit({ extraData: { use_alt_payment_source: 1 } });
  }

  submit(...args) {
    // Adds spinners. Otherwise, should be covered by default handlers.
    $('BODY').spinner();
    super.submit(...args).catch((error) => {
      $('BODY').clearSpinner();
      SG.debug(error);
    });
  }
}

$(document).ready(() => {
  /* eslint-disable no-new */
  new JoinFormUtils();

  $(document).on('click', '.alt-source-link', (e) => {
    e.preventDefault();
    const ajaxArgs = {
      url: e.target.dataset.url,
      dataType: 'json',
      data: { 'alt-source': e.target.dataset.source || 'auto' },
    };

    SG.apiPost(ajaxArgs).then((data) => {
      if (data.redir) {
        window.location.assign(data.redir);
      } else {
        const msg = SG.userLang() === 'es'
          ? 'El intento de obtener un enlace al procesador de pago alternativo'
          + ' falló.'
          : 'Attempt to get a link to alternate payment processor failed.';
        SG.userError(msg);
      }
    });
  });

  const offerExchangeCode = $('#offer-exchange-code').val();
  if (offerExchangeCode && $('.special-offer').length) {
    // Disable the special offer banner if no longer applicable.
    $('label input[type=radio]').on('change', () => {
      if ($('input[type=radio]:checked').val() === offerExchangeCode) {
        $('.special-offer').removeClass('disabled');
      } else {
        $('.special-offer').addClass('disabled');
      }
    });
  }

  $('#member-join').on('click', '#new-member', (e) => {
    $('#id_is_member').val('False');
    $('#old-member').removeClass('selected');
    $(e.currentTarget).addClass('selected');
  });

  $('#member-join').on('click', '#old-member', (e) => {
    $('#id_is_member').val('True');
    $('#new-member').removeClass('selected');
    $(e.currentTarget).addClass('selected');
  });

  function getSocialAuthParams() {
    return new URLSearchParams({
      plan_code: $('input[name="plan"]:checked').val(),
      offer_code: (
        $('#member-join').find('.social-auth-buttons').data('offerCode') || ''
      ),
    });
  }

  $('#google-oidc-join').click(function googleJoin(e) {
    e.preventDefault();
    const params = getSocialAuthParams();
    params.append('prompt', 'select_account');
    window.location.assign(`${this.href}?${params}`);
  });

  $('#twitter-join').click(function socialJoin(e) {
    e.preventDefault();
    const params = getSocialAuthParams();
    window.location.assign(`${this.href}?${params}`);
  });
});
